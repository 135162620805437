<template>
	<main class="vcc-main vcc-main__kb">
		<h1 class="visually-hidden">База знаний</h1>
		<h2 class="heading heading__hr hr__bold">Нормативно-правовые акты</h2>
		<section class="materials-main">
			<section class="kb--aside">
				<nav class="kb--nav">
					<ul class="kb-nav--list back">
						<li><router-link :to="{ name: 'Rlas' }" class="link__btn btn__blue">Назад</router-link></li>
					</ul>
				</nav>
				<small-email-form type="rla"/>
			</section>
			<section class="list-wth-filters rla-page">
				<article class="rla-description">
					<h3 class="subheading black">{{thisRla.title}}</h3>
					<p>{{thisRla.description || 'Нет описания'}}</p>
					<div class="rla-description--btn-wrapper">
						<a :href="link" class="link__btn btn__blue">Скачать</a>
					</div>
				</article>
				<section class="search-section">
					<form @submit.prevent="">
						<label for="webinars-search" class="label__input">Поиск по названию</label>
						<input type="text" name="webinars-search" id="webinars-search" class="input" placeholder="Начните вводить название" v-model="search_req" list="webinars-titles">
					</form>
					<fieldset class="filters-wrapper filters-wrapper__materials" v-show="true">
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="all" class="filter-checkbox visually-hidden" :value="null" @click="filter.filter_by = []" :checked="filter.filter_by.length<1">
							<label class="link__btn btn__white btn__filter" for="all">Все материалы</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="analytics" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Анализ изменений в законодательстве">
							<label class="link__btn btn__white btn__filter" for="analytics">Анализ изменений в законодательстве</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="methodukaz" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Методические указания">
							<label class="link__btn btn__white btn__filter" for="methodukaz">Методические указания</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="methodrec" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Методические рекомендации">
							<label class="link__btn btn__white btn__filter" for="methodrec">Методические рекомендации</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="articles" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Статьи и мнения экспертов">
							<label class="link__btn btn__white btn__filter" for="articles">Статьи и мнения экспертов</label>
						</div>
					</fieldset>
					<section class="sorting-section">
						<hr class="hr__blue">
						<div class="sorting--info">
							<dl class="dl__theme sorting-section--dl">
								<dt>Соответствует запросу</dt>
								<dd><b>{{filteredItems.length}}</b></dd>
							</dl>
							<dl class="dl__theme sorting-section--dl">
								<dt>Всего в базе</dt>
								<dd><b>{{getRlas.length}}</b></dd>
							</dl>
							<dl class="dl__theme dl--filter sorting-section--dl">
								<dt><b>Сортировать</b></dt>
								<dd>
									<div class="filter-group">
										<input type="radio" name="order" id="asc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="false">
										<label for="asc" class="sorting-by">с начала</label>
									</div>
									<div class="filter-group">
										<input type="radio" name="order" id="desc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="true">
										<label for="desc" class="sorting-by">с конца</label>
									</div>
									<div :class="['filter-group', {'chrome-date': isChrome }]">
										<input type="date" name="sort-date" id="filter-date" :class="['sort-date', 'visually-hidden', 'date-visually-hidden', {'date-not-empty': filter.date != null}]" v-model="filter.date">
										<label for="filter-date" class="sorting-by">
											по дате
											<svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .66a.57.57 0 0 1-.21.45L4.36 3.87a.57.57 0 0 1-.73 0L.21 1.01A.57.57 0 0 1 .94.13L4 2.7 7.06.22A.57.57 0 0 1 8 .66Z" fill="#9C9D9D"/></svg>
										</label>
									</div>
								</dd>
							</dl>
						</div>
					</section>
				</section>
				<ul class="kb--list" v-if="filteredItems.length > 0">
					<li v-for="material, key in filteredItems" :key="'rla-'+key">
						<vcc-kb-card :card-info="material" card-type="material" />
					</li>
				</ul>
				<div class="no-webinars" v-else>
					<h4>По вашему запросу не нашлось материалов</h4>
					<p>Попробуйте применить другие фильтры или выбрать другой нормативно-правовой акт.</p>
				</div>
			</section>
		</section>
	</main>
</template>

<script>
import SmallEmailForm from '@/components/forms/SmallEmailForm.vue'
import KbCard from '@/components/cards/KbCard.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	data() {
		return {
			search_req: null,
			filter: {
				date : null,
				sort_by: true,
				filter_by: []
			},
		}
	},
	components: {
		'vcc-kb-card': KbCard,
		'small-email-form': SmallEmailForm
	},
	props: ['id'],
	computed: {
		...mapGetters(['getRlas', 'getMaterialsWithRlas']),
		link() {
			const api_url = process.env.VUE_APP_STATIC_STORAGE
			return this.thisRla.link.search('http') < 0 ? api_url + this.thisRla.link : this.thisRla.link
		},
		filteredItems() {
			const materials = this.materialsByThisRla
			const searched_materials = this.search_req != null ? materials.filter(item => item.title.toLowerCase().includes(this.search_req.toLowerCase())) : materials
			return searched_materials.sort((a, b) => {
				if(this.filter.sort_by) {
					return b.id - a.id
				} else {
					return a.id - b.id
				}
			})
			.filter((item) => {
				if(this.filter.filter_by.length > 0) {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.isEventToday(item.created, this.filter.date) ? this.filter.filter_by.includes(item.type) : false
					} else {
						return this.filter.filter_by.includes(item.type)
					}
				} else {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.isEventToday(item.created, this.filter.date) ? item : false
					} else {
						return item
					}
				}

			})
		},
		materialsByThisRla() {
			return this.getMaterialsWithRlas.filter(material => {
				return material.regulatory_legal_acts.some(rla => rla.id == this.id) ? material : false
			})
		},
		thisRla() {
			return this.getRlas.filter(rla => rla.id == this.id)[0]
		}
	},
	methods: mapActions(['fetchAllRlas', 'fetchAllMaterials']),
	created() {
		this.fetchAllRlas()
		if(this.getMaterialsWithRlas.length < 1) this.fetchAllMaterials()
	}

}
</script>

<style>

</style>
