<template>
	<form @submit.prevent="sendEmail(type)" class="subscribe-form__aside" v-if="type === 'business_types'">
		<transition name="fade-in">
			<fieldset v-if="delivered === 'idle'">
				<legend>Подпишитесь на обновление нашей базы данных:</legend>
				<input v-model="user_data.email" type="email" name="email" id="email" class="input" placeholder="Ваша электронная почта..." required>
				<button type="submit" class="btn__transparent-bg">Подписаться</button>
			</fieldset>
			<article v-else-if="delivered === 'conflict'" class="sf--message sf--message__conflict">
				<strong>Вы уже подписаны на этот раздел Базы знаний</strong>
			</article>
			<article v-else-if="delivered === 'success'" class="sf--message sf--message__success">
				<strong>Получилось!</strong>
				<p>Теперь вы будете получать уведомления о появлении новых материалов нашей Базы знаний на свой почтовый ящик.</p>
			</article>
			<article v-else-if="delivered === 'fail'" class="sf--message sf--message__fail">
				<strong>Не получилось...</strong>
				<p>Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
			</article>
		</transition>
	</form>
	<form @submit.prevent="sendEmail(type)" class="subscribe-form__aside" v-else-if="type === 'materials'">
		<transition name="fade-in">
			<fieldset v-if="delivered === 'idle'">
				<legend>Подпишитесь на обновление нашей базы данных:</legend>
				<input v-model="user_data.email" type="email" name="email" id="email" class="input" placeholder="Ваша электронная почта..." required>
				<button type="submit" class="btn__transparent-bg">Подписаться</button>
			</fieldset>
			<article v-else-if="delivered === 'conflict'" class="sf--message sf--message__conflict">
				<strong>Вы уже подписаны на этот раздел Базы знаний</strong>
			</article>
			<article v-else-if="delivered === 'success'" class="sf--message sf--message__success">
				<strong>Получилось!</strong>
				<p>Теперь вы будете получать уведомления о появлении новых материалов нашей Базы знаний на свой почтовый ящик.</p>
			</article>
			<article v-else-if="delivered === 'fail'" class="sf--message sf--message__fail">
				<strong>Не получилось...</strong>
				<p>Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
			</article>
		</transition>
	</form>
	<form @submit.prevent="sendEmail(type)" class="subscribe-form__aside" v-else-if="type === 'rla'">
		<transition name="fade-in">
			<fieldset v-if="delivered === 'idle'">
				<legend>Подпишитесь на обновление нашей базы данных:</legend>
				<input v-model="user_data.email" type="email" name="email" id="email" class="input" placeholder="Ваша электронная почта..." required>
				<button type="submit" class="btn__transparent-bg">Подписаться</button>
			</fieldset>
			<article v-else-if="delivered === 'conflict'" class="sf--message sf--message__conflict">
				<strong>Вы уже подписаны на этот раздел Базы знаний</strong>
			</article>
			<article v-else-if="delivered === 'success'" class="sf--message sf--message__success">
				<strong>Получилось!</strong>
				<p>Теперь вы будете получать уведомления о появлении новых материалов нашей Базы знаний на свой почтовый ящик.</p>
			</article>
			<article v-else-if="delivered === 'fail'" class="sf--message sf--message__fail">
				<strong>Не получилось...</strong>
				<p>Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
			</article>
		</transition>
	</form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data() {
		return {
			user_data: {
				email: null,
			}
		}
	},
	props: ['type'],
	computed: {
		...mapGetters(['getStatus']),
		delivered() {
			let status = null
			if(this.getStatus != null) {
				switch (this.getStatus) {
					case 200:
						status = 'success'
						break;
					case 409:
						status = 'conflict'
						break;
					default:
						status = 'fail'
						break;
				}
				return status
			} else {
				status = 'idle'
				return status
			}
		}
	},
	methods: {
		...mapActions(['subscribeUser', 'cleanStatusCode']),
		sendEmail(type) {
			this.user_data.subscription_type = type
			this.subscribeUser(this.user_data)
		}
	},
	beforeDestroy() {
		this.cleanStatusCode()
	}
}
</script>
